<template>
	<div class="cw-app-wrapper">
		<div class="cw-app-container edit">
			<MStepper />
			<h1 class="tit_page px-6">계약 확인</h1>
			<div class="cw-app-container edit">
				<p class="txt_body1">
					안녕하세요. 코웨이㈜입니다.
					<br />
					{{ name }} 고객님의 주문이 접수되었습니다. <br />
					아래 주문 내용을 확인 후 다음을 눌러 서명을 진행해 주세요.
				</p>
				<ul class="info_list">
					<li>
						<dt>고객명</dt>
						<dd>{{ name }}</dd>
					</li>
					<li>
						<dt>전화번호</dt>
						<dd>{{ $options.filters.phone(phone) }}</dd>
					</li>
					<li>
						<dt>주문번호</dt>
						<dd>{{ orderNo }}</dd>
					</li>
					<!-- <li>
						<dt>확인유효기간</dt>
						<dd>{{ validDate }}</dd>
					</li> -->
					<!-- <li v-if="regulRecpType && regulRecpType !== 'undefined '"> -->
					<li v-if="this.sumAmt.MONTH_AMT !== 0">
						<dt>정기결제</dt>
						<dd>
							{{ regulRecpType }}
							<br v-if="dpParams && dpParams.account" />
							{{ dpParams && dpParams.account ? dpParams.account : '' }}
						</dd>
					</li>
					<li v-if="recpType && recpType !== 'undefined'">
						<dt>납부금 결제</dt>
						<dd>{{ recpType }}</dd>
					</li>
					<li>
						<dt>설치 주소</dt>
						<dd>{{ setupAddress }}</dd>
					</li>
				</ul>
				<div class="inner_box my-0">
					<div class="summay_list ">
						<dl class="bg_gray px-6">
							<dt>
								주문 요약
							</dt>
							<dd class="primary--text">
								총 <strong>{{ orderList.length }}</strong
								>개
							</dd>
						</dl>
					</div>
					<div class="summay_list  px-6">
						<template v-for="(sum, i) in groupbySumList">
							<dl :key="i">
								<dt>
									{{ getPrdName(sum.code) }}
								</dt>
								<dd>{{ sum.sale }} / {{ sum.cnt }}개</dd>
							</dl>
						</template>
						<dl class="gray_row2">
							<dt>
								구분
							</dt>
							<dd>납부금액 / 정기결제(매월)</dd>
						</dl>
						<dl class="no_bor">
							<dt>
								금액
							</dt>
							<dd>
								<span class="col-6"
									>{{ webOrderSum.sumOfRecpAmt | comma }}원/</span
								>
								<span class="col-6"
									>{{ webOrderSum.sumOfMonthAmt | comma }}원</span
								>
							</dd>
						</dl>
						<dl class="no_bor">
							<dt class="primary--text">
								할인
							</dt>
							<dd class="primary--text">
								<span class="col-6"
									>-{{ webOrderSum.sumOfRecpDcAmt | comma }}원/</span
								>
								<span class="col-6"
									>-{{ webOrderSum.sumOfMonthDcAmt | comma }}원</span
								>
							</dd>
						</dl>
						<dl class="">
							<dt class="bold">
								합계
							</dt>
							<dd class="bold">
								<span class="col-6"
									>{{ webOrderSum.totalSumOfRecpAmt | comma }}원/</span
								>
								<span class="col-6"
									>{{ webOrderSum.totalSumOfMonthAmt | comma }}원</span
								>
							</dd>
						</dl>
						<div class="infor_more">
							납부금액 : 등록비, 일시불 구매, 설치비, 계약금
						</div>
						<div class="infor_more">
							정기결제(매월) : 렌탈, 자체할부, <br />
							멤버십 비용
						</div>
					</div>
				</div>
				<div class="u-agree-box">
					<div class="rules">
						<div class="all">
							<ACheckbox
								class="bold_check_2"
								:checked="checkedAll"
								@change="changeCheckedAll($event)"
								:label="'모두 확인했습니다.'"
							/>
						</div>
						<ul class="list">
							<template v-for="(agree, i) in agreeList">
								<li :key="i">
									<ACheckbox
										class="item"
										:checked="agree.checked"
										@checked="agreeChk($event, agreeList, i)"
										:label="agree.cateName"
									/>
									<v-btn
										class="file"
										@click="openPdfPop(agree)"
										download
										text
										:ripple="false"
										depressed
									></v-btn>
								</li>
							</template>
						</ul>
					</div>
				</div>
				<v-expansion-panels focusable class="type_01 mt-6">
					<v-expansion-panel class="accodion_select">
						<v-expansion-panel-header class=""
							><h3 class="sub_tit_type_01 type_01">주문내역 상세 보기</h3>
							<p class="selected"></p
						></v-expansion-panel-header>
						<v-expansion-panel-content class="bg_white">
							<div class="summay_list px-6 mt-4">
								<!--div class="infor_more">
									상세 보기의 금액은 프로모션 등이 미적용된 원가로 표시됩니다.
								</div-->
								<div class="infor_more">
									기기별로 주문번호가 부여됩니다.
								</div>
							</div>
							<template v-for="(ord, i) in orderList">
								<div class="px-6" :key="i">
									<h3 class="mb-4 mt-4">
										<span>주문{{ i + 1 }}.</span>
										<strong class="primary--text">
											{{ ord.GOODS_NM }} {{ getPrdName(ord.PRODH9) }}
										</strong>
									</h3>
									<div class="wrap_list_detail ">
										<dl>
											<dt>주문번호</dt>
											<dd>{{ ord.ORDER_NO }}</dd>
										</dl>
										<dl class="list_detail">
											<dt>구매방식</dt>
											<dd>{{ ord.E_SALE_GUBUN }}</dd>
										</dl>
										<dl
											class="list_detail"
											v-if="ord.GOODS_AMT > 0 && canceledOrderChk(i)"
										>
											<dt>수납정보</dt>
											<dd>{{ ord.GOODS_AMT | comma }} 원</dd>
										</dl>
										<dl
											class="list_detail"
											v-if="ord.GOODS_AMT > 0 && canceledOrderChk(i)"
										>
											<dt></dt>
											<dd>
												{{ ord.GOODS_AMT | comma }} 원<br />{{
													ord.T_A0401_PAYMENT_LIST.filter(
														x => x.GUBUN === '2'
													)[0]
														? ord.T_A0401_PAYMENT_LIST.filter(
																x => x.GUBUN === '2'
														  )[0].CARDCOM_NM
														: ''
												}}
												{{
													getMaskedAccNo(
														ord.T_A0401_PAYMENT_LIST.filter(
															x => x.GUBUN === '2'
														)[0]
															? ord.T_A0401_PAYMENT_LIST.filter(
																	x => x.GUBUN === '2'
															  )[0].CARD_NO
															: ''
													)
												}}
											</dd>
										</dl>
										<dl
											class="list_detail"
											v-if="ord.MONTH_AMT > 0 && canceledOrderChk(i)"
										>
											<dt>정기결제</dt>
											<dd>{{ ord.MONTH_AMT | comma }} 원/월</dd>
										</dl>
										<dl
											class="list_detail"
											v-if="
												ord.CARDCOM_NM && ord.CARD_NO && canceledOrderChk(i)
											"
										>
											<dt></dt>
											<dd>
												{{ ord.MONTH_AMT | comma }} 원/월<br />{{
													ord.CARDCOM_NM ? ord.CARDCOM_NM : ''
												}}
												{{ ord.CARD_NO ? getMaskedAccNo(ord.CARD_NO) : '' }}
											</dd>
										</dl>

										<dl class="list_detail" v-if="canceledOrderChk(i)">
											<dt>설치일시</dt>
											<dd>
												{{
													deliveryInfos[i].workerType !== 'EXPRESS'
														? dateToBookingText({
																bookingDate: deliveryInfos[i].bookingDate,
																bookingSlot: deliveryInfos[i].bookingSlot,
																bookingSlotTime:
																	deliveryInfos[i].bookingSlotTime
														  })
														: '택배 배송'
												}}
											</dd>
										</dl>

										<dl class="list_detail" v-if="canceledOrderChk(i)">
											<dt>설치주소</dt>
											<dd>{{ ord.CITY1 }} {{ ord.STREET }}</dd>
										</dl>
										<dl v-if="canceledOrderChk(i) === false">
											<dt>주문상태</dt>
											<dd>주문삭제</dd>
										</dl>
									</div>
								</div>
							</template>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
				<div class="bottom_box">
					<v-btn
						color="primary"
						class="btn_col2 col-12"
						@click="signature()"
						:disabled="isDisableBtn"
					>
						전자계약서 보기
					</v-btn>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { fetchEContract } from '@/apis/order.ec.api'
import { dateToBookingText } from '@/utils/schedule.js'
import { checkingStatus, fetchWebOrderInfoById } from '@/apis/order.status.api'
import filters from '@/mixins/filters'
import {
	getPrdName,
	getPdfLinkType,
	getAgreeCateName,
	groupbyOrderInfo
} from '@/utils/ec'
import { registerCallbackToApp } from '@/utils/navigation'
import { getMaskedAccNo } from '@/utils/ec'
export default {
	mixins: [filters],
	data() {
		return {
			name: '',
			phone: '',
			orderNo: '',
			validDate: '',
			regulRecpType: '',
			recpType: '',
			setupAddress: '',
			orderList: [],
			orderStatusList: [],
			groupbySumList: [],
			sumList: [],
			payRecp: {
				AF_BANK_CD_NM: '',
				AF_CARD_NO: ''
			},
			webOrderSum: [],
			recpInfos: {},
			dpParams: {}, // 복합결제 가상계좌
			checkedAll: false,
			agreeList: [], // 약관 목록
			deliveryInfos: [],
			sumAmt: ''
		}
	},
	computed: {
		isDisableBtn() {
			return !this.isCheckedAll()
		}
	},
	created() {
		this.initAppPage()
	},
	async mounted() {
		const mobOrderNo = this.$store.getters['verify/mobOrderNo']
		this.deliveryInfos = this.$store.getters['schedule/deliveryInfos']
		const info = await fetchWebOrderInfoById(mobOrderNo)
		const sum = JSON.parse(info.resultObject.orderInfoJson)
		this.webOrderSum = sum.PAY_SUMMARY_AMT

		// 일반 : 성명, 개인사업자: 사업자명
		const custType = this.$store.getters['verify/customerType']
		if (custType === 'L') {
			this.name = this.$store.getters['verify/bizName']
		} else {
			this.name = this.$store.getters['verify/custName']
		}

		this.phone = this.$store.getters['verify/custPhone']

		this.recpInfos = this.$store.getters['recp/recpInfos']

		if (this.recpInfos) {
			//정기결제
			this.payRecp = this.$store.getters['pay/paymentOrders']
			if (!this.payRecp || Object.keys(this.payRecp).length === 0) {
				this.payRecp = {
					AF_BANK_CD_NM: '',
					AF_CARD_NO: ''
				}
				//가상계좌
				if (this.recpInfos.mthd === '16') {
					const dpParams = this.$store.getters['recp/dpParams']
					if (dpParams && Object.keys(dpParams).length > 0) {
						const acc = dpParams.account.split(' ')
						if (acc && acc.length > 1) {
							this.payRecp = {
								AF_BANK_CD_NM: acc[0],
								AF_CARD_NO: acc[1]
							}
						}
					}
				}
			}
			if (this.recpInfos.mthd === '90') {
				this.dpParams = this.$store.getters['recp/dpParams']
			}
		}

		// 계약확인 조회
		const params = {
			mobOrderNo: this.$store.getters['verify/mobOrderNo']
		}
		const res = await fetchEContract(params)
		this.orderList = res.resultObject.data[0].setup
		this.sumAmt = res.resultObject.data[0].sumAmt
		this.sumList = res.resultObject.data[0].sum

		// 약관
		this.orderList.forEach((v, i) => {
			if (this.canceledOrderChk(i)) {
				let contractId = v.CONTRACT_ID
				let agree = {}
				agree.checked = false
				agree.sellType = contractId === '1' ? '일시불' : '렌탈'
				agree.cateName = this.getAgreeCateName(v.CONTRACT_TYPE)
				agree.label = `${agree.cateName} ${agree.sellType} 약관`
				agree.pdfLinkType = v.CONTRACT_TYPE
				const listStr = JSON.stringify(this.agreeList)
				if (listStr.indexOf(JSON.stringify(agree)) === -1) {
					this.agreeList.push(agree)
				}
			}
		})

		let ordNoStr = ''
		this.orderList.forEach((res, i) => {
			if (i === 0) {
				ordNoStr = `${res.ORDER_NO}`
			} else {
				ordNoStr = `${ordNoStr}, ${res.ORDER_NO}`
			}
		})
		this.orderNo = ordNoStr

		// 주문요약 셋팅
		this.groupbySumList = await groupbyOrderInfo(this.sumList, this.orderNo)

		if (this.orderList) {
			this.validDate = this.orderList[0].EXPIRE
			if (this.orderList.length > 1) {
				this.regulRecpType = `${
					this.orderList[0].CARDCOM_NM !== ''
						? this.orderList[0].CARDCOM_NM
						: this.payRecp.AF_BANK_CD_NM
				} ${
					this.orderList[0].CARD_NO
						? this.getMaskedAccNo(this.orderList[0].CARD_NO)
						: this.getMaskedAccNo(this.payRecp.CARD_NO)
				} 외 ${this.orderList.length - 1}건`
				this.setupAddress = `${this.orderList[0].CITY1} ${
					this.orderList[0].STREET
				} 외 ${this.orderList.length - 1}곳`
				this.recpType = `${
					this.orderList[0].T_A0401_PAYMENT_LIST.filter(x => x.GUBUN === '2')[0]
						.CARDCOM_NM
				} ${this.getMaskedAccNo(
					this.orderList[0].T_A0401_PAYMENT_LIST.filter(x => x.GUBUN === '2')[0]
						.CARD_NO
				)}`
			} else {
				this.regulRecpType = `${
					this.orderList[0].CARDCOM_NM !== ''
						? this.orderList[0].CARDCOM_NM
						: this.payRecp.AF_BANK_CD_NM
				} ${
					this.orderList[0].CARD_NO
						? this.getMaskedAccNo(this.orderList[0].CARD_NO)
						: this.getMaskedAccNo(this.payRecp.CARD_NO)
				}`
				this.setupAddress = `${this.orderList[0].CITY1} ${this.orderList[0].STREET}`
				this.recpType = `${
					this.orderList[0].T_A0401_PAYMENT_LIST.filter(x => x.GUBUN === '2')[0]
						.CARDCOM_NM
				} ${this.getMaskedAccNo(
					this.orderList[0].T_A0401_PAYMENT_LIST.filter(x => x.GUBUN === '2')[0]
						.CARD_NO
				)}`
			}
		}
		//주문상태조회
		await this.createOrderStatusList()
	},
	methods: {
		getPrdName,
		getAgreeCateName,
		getPdfLinkType,
		getMaskedAccNo,
		dateToBookingText,
		agreeChk(val, agreeList, i) {
			agreeList[i].checked = val
			this.checkedAll = !agreeList.some(x => !x.checked)
		},
		// getMaskedAccNo(accNo) {
		// 	if (accNo) {
		// 		if (accNo.length < 15) {
		// 			return this.$options.filters.star(accNo, 5)
		// 		} else {
		// 			return this.$options.filters.star(accNo, 7, 12)
		// 		}
		// 	} else {
		// 		return ''
		// 	}
		// },
		canceledOrderChk(i) {
			if (
				this.orderList.length === this.orderStatusList.length &&
				this.orderStatusList[i].ORDER_STATUS === '203'
			) {
				return false
			} else {
				return true
			}
		},
		openPdfPop(agree) {
			let link = ''
			let item = agree.pdfLinkType
			switch (item) {
				case 'LVEE':
					{
						link = `https://files.sorder.coway.com/terms/${item}.pdf`
					}
					break
				case 'LCEE':
					{
						link = `https://files.sorder.coway.com/terms/${item}.pdf`
					}
					break
				case 'RVEE':
					{
						link = `https://files.sorder.coway.com/terms/${item}.pdf`
					}
					break
				case 'RCEE':
					{
						link = `https://files.sorder.coway.com/terms/${item}.pdf`
					}

					break
				case 'LVMT':
					{
						link = `https://files.sorder.coway.com/terms/${item}.pdf`
					}
					break
				case 'LCMT':
					{
						link = `https://files.sorder.coway.com/terms/${item}.pdf`
					}
					break
				case 'RVMT':
					{
						link = `https://files.sorder.coway.com/terms/${item}.pdf`
					}
					break
				case 'RCMT':
					{
						link = `https://files.sorder.coway.com/terms/${item}.pdf`
					}
					break
				case 'RVCP':
					{
						link = `https://files.sorder.coway.com/terms/${item}.pdf`
					}
					break
				case 'RCCP':
					{
						link = `https://files.sorder.coway.com/terms/${item}.pdf`
					}
					break
			}
			window.open(link)
		},
		isCheckedAll() {
			let count = 0
			this.agreeList.forEach(agree => {
				this.$log(agree.checked)
				if (agree.checked) {
					count++
				}
			})
			if (count === this.agreeList.length) {
				return true
			} else {
				return false
			}
		},
		changeChecked(value, i) {
			this.$log(i)
			if (value) {
				if (this.isCheckedAll()) this.checkedAll = this.agreeList[i].checked
			} else {
				this.checkedAll = value
			}
		},
		changeCheckedAll(value) {
			this.agreeList.forEach((agree, i) => {
				this.$set(this.agreeList[i], 'checked', value)
			})
		},
		signature() {
			const orderResult = this.$store.getters['inst/orderInfoResult']
			const mobOrderNo = this.$store.getters['verify/mobOrderNo']
			if (this.orderList) {
				let url = ''
				if (this.orderList.length === 1) {
					const formId = orderResult[0].CONTRACT_TYPE
					const ordNo = this.orderList[0].ORDER_NO
					url = `${process.env.VUE_APP_SIGN_URL}/smarteform/reqClientRental/requestEmptyJspRental.jcg?formId=${formId}&pdfType=new&ordNo=${ordNo}&wOrdNo=${mobOrderNo}`
					location.href = url
				} else {
					let formIdStr = ''
					let ordNoStr = ''
					this.orderList.forEach((res, i) => {
						if (i === 0) {
							formIdStr = `${orderResult[i].CONTRACT_TYPE}`
							ordNoStr = `${res.ORDER_NO}`
						} else {
							formIdStr = `${formIdStr},${orderResult[i].CONTRACT_TYPE}`
							ordNoStr = `${ordNoStr},${res.ORDER_NO}`
						}
					})
					this.$log(formIdStr, ordNoStr)
					url = `${process.env.VUE_APP_SIGN_URL}/smarteform/reqClientRental/requestRental.jcg?formId=${formIdStr}&pdfType=new&ordNo=${ordNoStr}&wOrdNo=${mobOrderNo}`
					location.href = url
				}
			}
		},
		initAppPage() {
			registerCallbackToApp({})
		},
		async createOrderStatusList() {
			const orderNoList = []
			for (const order of this.orderList) {
				orderNoList.push(order.ORDER_NO)
			}

			const response = await checkingStatus({
				saleCd: this.$store.getters['common/codyNo'],
				kunnr: this.$store.getters['verify/custNo'],
				orderNos: orderNoList
			})
			this.$log('response.resultObject', response.resultObject)
			this.orderStatusList = response.resultObject.T_ORDER_STATUS
		}
	}
}
</script>

<style scoped lang="scss">
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
	> .v-expansion-panel--active {
	border-radius: 0;
}
</style>
